import { ShipperOrdersQuickFilterEnum } from 'common/utils/api/models';

export enum QueryKeysEnum {
    ordersPage = 'orders-page',
    ordersSort = 'orders-sort',
    ordersFilters = 'orders-filters',
}

export enum QueryFiltersKeysEnum {
    origin = 'origin',
    destination = 'destination',
    pickupDateFrom = 'pickupDockingHoursFrom',
    pickupDateTo = 'pickupDockingHoursTo',
    dropOffDateFrom = 'dropOffDockingHoursFrom',
    dropOffDateTo = 'dropOffDockingHoursTo',
    createdDateFrom = 'addedDateFrom',
    createdDateTo = 'addedDateTo',
    createdById = 'createdById',
    createdByName = 'createdByName',
    dictTrailerId = 'dictTrailerId',
    trailerModel = 'trailerModel',
    onlySpotOrders = 'onlySpotOrders',
    onlyContractedOrders = 'onlyContractedOrders',
    quickFilter = 'quickFilter',
    searchText = 'searchText',
    source = 'source',
}

export type QueryFiltersT = Partial<{
    [QueryFiltersKeysEnum.origin]: QueryParam.StringT;
    [QueryFiltersKeysEnum.destination]: QueryParam.StringT;
    [QueryFiltersKeysEnum.pickupDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.pickupDateTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dropOffDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dropOffDateTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdDateTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdByName]: QueryParam.StringT;
    [QueryFiltersKeysEnum.createdById]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dictTrailerId]: QueryParam.StringT;
    [QueryFiltersKeysEnum.trailerModel]: QueryParam.StringT;
    [QueryFiltersKeysEnum.onlySpotOrders]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.onlyContractedOrders]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.quickFilter]: ShipperOrdersQuickFilterEnum;
    [QueryFiltersKeysEnum.searchText]: QueryParam.StringT;
    [QueryFiltersKeysEnum.source]: QueryParam.StringT;
}>;
